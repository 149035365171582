import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './sites/home/home.component';
import { UnternehmenComponent } from './sites/unternehmen/unternehmen.component';
import { PrototypingComponent } from './sites/prototyping/prototyping.component';
import { KompetenzenComponent } from './sites/kompetenzen/kompetenzen.component';
import { KontaktComponent } from './sites/kontakt/kontakt.component';
import { ServiceComponent } from './sites/service/service.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ImpressumComponent } from './sites/impressum/impressum.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    UnternehmenComponent,
    PrototypingComponent,
    KompetenzenComponent,
    KontaktComponent,
    ServiceComponent,
    FooterComponent,
    NavbarComponent,
    ImpressumComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
