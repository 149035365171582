import { Component, OnInit } from '@angular/core';
import { HeaderContentService } from 'src/app/services/header-content.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {
  private title:string = "Home";
  private metaTags = {description: "Die AMI GmbH ist spezialisiert auf die Entwicklung von kundenspezifischen " +
                                   "HMI-Systemen und Dienstleistungen im Bereich der Entwicklung von Embedded Software.",
                      keywords : "Home, Homepage"}

  constructor(
    private headerService: HeaderContentService) {}

  ngOnInit() {
    this.headerService.initHeader(this.title, this.metaTags)
  }

}
