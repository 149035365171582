import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './sites/home/home.component';
import { UnternehmenComponent } from './sites/unternehmen/unternehmen.component';
import { PrototypingComponent } from './sites/prototyping/prototyping.component';
import { KompetenzenComponent } from './sites/kompetenzen/kompetenzen.component';
import { KontaktComponent } from './sites/kontakt/kontakt.component';
import { ServiceComponent } from './sites/service/service.component';
import { ImpressumComponent } from './sites/impressum/impressum.component';

const routes: Routes = [
    { path: '',
      redirectTo: 'home',
      pathMatch: 'full'
    },
    { path: 'home',
      component: HomeComponent 
    },
    { path: 'company',      
      component: UnternehmenComponent 
    },
    { path: 'prototyping',      
      component: PrototypingComponent 
    },
    { path: 'competences',      
      component: KompetenzenComponent 
    },
    { path: 'contact',      
      component: KontaktComponent 
    },
    { path: 'service',      
      component: ServiceComponent 
    },
    { path: 'imprint',
      component: ImpressumComponent
    },
    { path: '**',
      redirectTo: 'home'
    }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
