import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class HeaderContentService {

  constructor(
    private title: Title,
    private meta: Meta) {}

  ngOnInit() {}

  public initHeader(title:string, metaTags:Object) {
    this.changeTitle(title);
    for (const [key, value] of Object.entries(metaTags)) {
      this.updateMetaTag(key, value)
    }
  }

  private changeTitle(title:string) {
    if(!title)
      return
    this.title.setTitle([title, this.title.getTitle()].join(" - "));
  }

  private updateMetaTag(name:string, content:string) {
    if(!name && !content)
      return
    switch(name) {
      case "keywords":
        this.meta.updateTag({name: name, content: [this.getMetaTag(name).content, content].join(", ")});
      default:
        this.meta.updateTag({name: name, content: this.getMetaTag(name).content + content});
        break
    }
  }

  private getMetaTag(name:string) {
    return this.meta.getTag(`name= "${name}"`);
  }
}
