import { Component, OnInit } from '@angular/core';
import { HeaderContentService } from 'src/app/services/header-content.service';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.sass']
})
export class ServiceComponent implements OnInit {
  private title:string = "Service";
  private metaTags = {description: "",
                      keywords: "service, tätigkeiten, aufgabengebiet"};

  constructor(
    private headerService: HeaderContentService) {}

  ngOnInit() {
    this.headerService.initHeader(this.title, this.metaTags);
  }

}
