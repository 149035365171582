import { Component, OnInit } from '@angular/core';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { HeaderContentService } from 'src/app/services/header-content.service';

@Component({
  selector: 'app-prototyping',
  templateUrl: './prototyping.component.html',
  styleUrls: ['./prototyping.component.sass']
})
export class PrototypingComponent implements OnInit {
  public faEnvelope = faEnvelope;
  private title:string = "Prototyping";
  private metaTags = {description: "",
                      keywords: "prototyping, rapid control prototyping, muster, vorserie, eol prüfstände, eol, end of line, serienproduktion, produktion"};

  constructor(
    private headerService: HeaderContentService) {}

  ngOnInit() {
    this.headerService.initHeader(this.title, this.metaTags);
  }

}
