import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.sass']
})
export class NavbarComponent implements OnInit {
  private menu;
  
  constructor() { }

  ngOnInit() {
    this.menu = document.querySelector(".navbar-menu");
    const toggler = <HTMLInputElement> document.getElementById("toggler");
    const navbar_links = document.querySelectorAll(".navbar-dropdown-menu-item")
    navbar_links.forEach(el => el.addEventListener("click", () => {
      this.toggleMenuCollapse();
      toggler.checked = false;
    }));
    toggler.addEventListener("change", () => this.toggleMenuCollapse());
  }

  private toggleMenuCollapse() {
    this.menu.classList.toggle("navbar-menu-mobile-expanded");
  }
}
