import { Component, OnInit } from '@angular/core';
import { HeaderContentService } from 'src/app/services/header-content.service';

@Component({
  selector: 'app-unternehmen',
  templateUrl: './unternehmen.component.html',
  styleUrls: ['./unternehmen.component.sass']
})
export class UnternehmenComponent implements OnInit {
  private title:string = "Unternehmen";
  private metaTags = {description: "Die AMI GmbH stellt sich vor. Finden sie zusätzliche Referenzen und offene Karrieremöglichkeiten.",
                      keywords: "unternehmen, company, über uns, referenzen,"};

  constructor(
    private headerService: HeaderContentService) {}

  ngOnInit() {
    this.headerService.initHeader(this.title, this.metaTags);
  }

}
