import { Component, OnInit } from '@angular/core';
import { HeaderContentService } from 'src/app/services/header-content.service';

@Component({
  selector: 'app-kompetenzen',
  templateUrl: './kompetenzen.component.html',
  styleUrls: ['./kompetenzen.component.sass']
})
export class KompetenzenComponent implements OnInit {
  private title:string = "Kompetenzen";
  private metaTags = {description: "",
                      keywords: "kompentenzen, angebot, aufgaben"};

  constructor(
    private headerService: HeaderContentService) {}

  ngOnInit() {
    this.headerService.initHeader(this.title, this.metaTags);
  }

}
