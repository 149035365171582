import { Component, OnInit } from '@angular/core';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { HeaderContentService } from 'src/app/services/header-content.service';

@Component({
  selector: 'app-kontakt',
  templateUrl: './kontakt.component.html',
  styleUrls: ['./kontakt.component.sass']
})
export class KontaktComponent implements OnInit {
  public faPhone = faPhone;
  private title:string = "Kontakt";
  private metaTags = {description: "",
                      keywords: "Kontakt, Map, Anschrift, Adresse, Telefon, E-mail"};

  constructor(
    private headerService: HeaderContentService) {}

  ngOnInit() {
    this.headerService.initHeader(this.title, this.metaTags);
  }

}
